import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {BroadcastActivateEventsDirective} from './broadcast-activate-events.directive'

@NgModule({
  declarations: [BroadcastActivateEventsDirective],
  imports: [CommonModule],
  exports: [BroadcastActivateEventsDirective],
})
export class BroadcastActivateEventsModule {}
