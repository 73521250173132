import {Inject, Injectable, RendererFactory2, ViewEncapsulation} from '@angular/core'
import {DOCUMENT} from '@angular/common'

@Injectable({providedIn: 'root'})
export class CanonicalLinkService {
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document,
  ) {}

  /**
   * Updates any existing canonical link in the head of the document, otherwise appends a new element
   * This method is useful as there can be only one canonical link element in the document
   */
  setCanonicalLink(url: string) {
    const current = this.getCanonicalLink()
    if (current) {
      current.href = url
    } else {
      this.addCanonicalLink(url)
    }
  }

  /**
   * Attaches a canonical link into the head element of the document
   */
  private addCanonicalLink(url: string): void {
    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: 'canonical link renderer',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {},
      })

      const link = renderer.createElement('link')
      const head = this.document.head

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.')
      }

      renderer.setAttribute(link, 'rel', 'canonical')
      renderer.setAttribute(link, 'href', url)

      renderer.appendChild(head, link)
    } catch (e) {
      console.error('Error within canonicalService : ', e)
    }
  }

  private getCanonicalLink(): HTMLLinkElement | undefined {
    return this.document.querySelector("link[rel='canonical']") || undefined
  }
}
