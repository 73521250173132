import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core'
import {Meta, Title} from '@angular/platform-browser'
import {hasPageMetadata, PageMetadata, StructuredData} from './page-metadata'
import {getSeoUrl} from './util'
import {ConfigService} from '../config/config.service'
import {BehaviorSubject, map, Observable, of, Subscription, zip} from 'rxjs'
import {CanonicalLinkService} from './canonical-link.service'
import {Router} from '@angular/router'
import {DOCUMENT} from '@angular/common'
import {Language} from '../i18n/language'

@Injectable({providedIn: 'root'})
export class PageMetadataService {
  private readonly _structuredData$ = new BehaviorSubject<StructuredData | undefined>(undefined)

  private metadataSub?: Subscription
  private availableLanguagesSub?: Subscription

  hrefLangLinkElements: HTMLLinkElement[] = []

  renderer: Renderer2

  constructor(
    private readonly metaService: Meta,
    private readonly titleService: Title,
    private readonly canonicalLinkService: CanonicalLinkService,
    private readonly router: Router,
    private readonly configService: ConfigService,
    private readonly rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory2.createRenderer(null, null)
  }

  get structuredData$(): Observable<StructuredData | undefined> {
    return this._structuredData$.asObservable()
  }

  applyRouteMetadata(hierarchy: unknown[]) {
    this.availableLanguagesSub?.unsubscribe()
    this.availableLanguagesSub = this.getAvailableLanguages(hierarchy).subscribe(langs => this.setHrefLang(langs))

    this.metadataSub?.unsubscribe()
    this.metadataSub = this.getMetadataFromHierarchy(hierarchy).subscribe(meta => {
      this.metaService.updateTag({property: 'og:site_name', content: 'Buildigo'})
      this.metaService.updateTag({property: 'og:url', content: getSeoUrl(this.configService.config) + this.router.url})

      this.canonicalLinkService.setCanonicalLink(
        getSeoUrl(this.configService.config, (meta?.canonicalUrl || this.router.url).replace(/\/$/, '')), // remove trailing slash
      )

      if (meta) {
        this.updateMetaTags(meta)
        this.clearMetaTags(meta)
        this._structuredData$.next(meta.structuredData)
      } else {
        // clear everything from the previous page
        this.clearMetaTags({})
        this._structuredData$.next(undefined)
      }
    })
  }

  private getMetadataFromHierarchy(hierarchy: unknown[]): Observable<PageMetadata | undefined> {
    // from the hierarchy, only keep components that implement "PageComponent"
    const componentsWithMetadata = hierarchy.filter(hasPageMetadata)
    if (componentsWithMetadata.length === 0) {
      // no component in the hierarchy define any metadata
      return of(undefined)
    }
    return zip(componentsWithMetadata.map(component => component.getPageMetadata())).pipe(
      map(pageMetas =>
        pageMetas.reduce((meta, current) => {
          // apply each component metadata, with priority to children over parents
          return {
            ...meta,
            ...current,
          }
        }),
      ),
    )
  }

  private getAvailableLanguages(hierarchy: unknown[]): Observable<Language[]> {
    const componentsWithMetadata = hierarchy.filter(hasPageMetadata)

    return zip(componentsWithMetadata.map(component => component.getPageAvailableLanguages())).pipe(
      map(languages => languages.flat()),
      map(languages => languages.filter((language): language is Language => language !== undefined)),
    )
  }

  /**
   * Updates page meta tags
   */
  private updateMetaTags(metadata: PageMetadata) {
    if (metadata.title) {
      this.titleService.setTitle(metadata.title)
      this.metaService.updateTag({property: 'og:title', content: metadata.title})
      this.metaService.updateTag({property: 'twitter:title', content: metadata.title})
    }

    if (metadata.description) {
      this.metaService.updateTag({name: 'description', content: metadata.description})
      this.metaService.updateTag({property: 'description', content: metadata.description})
      this.metaService.updateTag({property: 'og:description', content: metadata.description})
      this.metaService.updateTag({property: 'twitter:description', content: metadata.description})
    }

    if (metadata.imageUrl) {
      this.metaService.updateTag({property: 'og:image', content: metadata.imageUrl})
      this.metaService.updateTag({property: 'twitter:image', content: metadata.imageUrl})
    }

    if (metadata.twitterCard) {
      this.metaService.updateTag({property: 'twitter:card', content: metadata.twitterCard})
    }

    if (metadata.type) {
      this.metaService.updateTag({property: 'og:type', content: metadata.type})
    }

    if (metadata.doNotIndexPage) {
      this.metaService.updateTag({name: 'robots', content: 'noindex'})
    }
  }

  /**
   * Clears all tags that aren't defined
   */
  private clearMetaTags(meta: PageMetadata) {
    if (!meta.title) {
      this.titleService.setTitle('Buildigo')
      this.metaService.removeTag("property='og:title'")
      this.metaService.removeTag("property='twitter:title'")
    }

    if (!meta.description) {
      this.metaService.removeTag("property='description'")
      this.metaService.removeTag("property='og:description'")
      this.metaService.removeTag("property='twitter:description'")
    }

    if (!meta.imageUrl) {
      this.metaService.removeTag("property='og:image'")
      this.metaService.removeTag("property='twitter:image'")
    }

    if (!meta.doNotIndexPage) {
      this.metaService.removeTag("name='robots'")
    }

    // set some sane defaults as fallback
    if (!meta.type) {
      this.metaService.updateTag({property: 'og:type', content: 'website'})
    }

    if (!meta.twitterCard) {
      this.metaService.updateTag({property: 'twitter:card', content: 'summary'})
    }
  }

  private setHrefLang(availableLanguages: Language[]): void {
    // resetting the elements
    this.hrefLangLinkElements.forEach(e => e.remove())
    this.hrefLangLinkElements = []

    // creating the link tags
    this.hrefLangLinkElements = availableLanguages.map(l =>
      this.hrefLangLinkElement(this.getUrlForLocale(this.router.url, l), this.languageToLocale(l)),
    )

    // render
    this.hrefLangLinkElements.forEach(e => this.renderer.appendChild(this.document.head, e))
  }

  private getUrlForLocale(path: string, locale: string) {
    const splittedUrl = path.split('/')
    splittedUrl[1] = locale.slice(0, 2)
    return getSeoUrl(this.configService.config, splittedUrl.join('/'))
  }

  private hrefLangLinkElement(hrefValue: string, hrefLangValue: string) {
    const linkElement = this.renderer.createElement('link')
    linkElement.setAttribute('rel', 'alternate')
    linkElement.setAttribute('hreflang', hrefLangValue)
    linkElement.setAttribute('href', hrefValue)

    return linkElement
  }

  private languageToLocale(lang: Language): string {
    return `${lang}-CH`
  }
}
